<template>
  <div id="email-template-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" class="pl-0 config-breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click.native="!item.disabled && $router.push(item.href)" class="primary--text"
          :class="{'secondary--text': breadcrumbList.length > 1 && item.disabled}">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <email-template-form v-if="emailTemplateId" />
    <email-template-home v-else />
  </div>
</template>

<script>
export default {
  name: "EmailTemplates",
  metaInfo: {
    title: "Email Templates",
  },
  components: {
    emailTemplateForm: () => import("../template/components/TemplateForm"),
    emailTemplateHome: () => import("../template/components/TemplateHome"),
  },
  data: (vm) => ({
      emailTemplateId: vm.$route.params.emailTemplateId,
      breadcrumbList: [{
        text: 'Email Templates',
        disabled: true,
        href: '/email-templates',
      }]
  }),
  watch: {
    $route(newVal) {
      this.emailTemplateId = newVal.params.emailTemplateId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.emailTemplateId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.emailTemplateId == 'create' ? 'Create' : 'Update'} Template`,
          disabled: true,
          href: `/email-templates/${this.emailTemplateId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Email Templates',
          disabled: true,
          href: '/email-templates',
        }]
      }
    }
  }
};
</script>
